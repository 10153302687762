/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

var Bookings = (function () {
    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    var $templateBookingsItem;
    var $tplBookingsGroupItem;
    let last_groupfilter;

    function Init() {
        $templateBookingsItem = $('template#tplBookingsItem').contents().remove();
        $tplBookingsGroupItem = $('template#tplBookingsGroupItem').contents().remove();

        if (localState.bookings == undefined) {
            localState.bookings = {'itemIndex': 0, last_groupfilter: undefined};
        }

        // BuildFromSourceClient();
        renderList();
        renderClient(localState.bookings.last_groupfilter);

        $(document).on('click',  '#bookings-details .item .save-bookings', submitBooking)
                   .on('change', '#bookings-details form input', bookingsInput)
                   .on('show', '#bookings-details', BuildFromSourceClient)
                   .on('click', '#bookings-details .join-tcroom', joinTcRoom)
                   .on('click', '#bookings-details .watch-stream', watchStream)
                   .on('change', '#bookings-details input[type=checkbox]', bookingsChangeInput)
                   .on('click', '#bookings .bookingsGroupList .item', function () {
                       let gid = $(this).attr("data-id");
                       showDetails(gid);
                   });

        //$('#bookings article').find('[type=submit]').addClass('disabled');
    }

    function bookingsInput() {
        // $(this).closest('form').find('[type=submit]').removeClass('disabled');
        $('#bookings-details article').find('[type=submit]').removeClass('disabled');
    }

    function bookingsChangeInput() {
        var
            classList = 'state-checked animated pulse',
            $label = $(this).closest('label');

        if (this.type === 'radio') {
            // $label.siblings().removeClass(classList);
            $('#bookings .bookingsList .item .flex').removeClass(classList);
        }
        $label.toggleClass(classList, this.checked);
    }

    function submitBooking(e) {
        e.preventDefault();

        // var id = $(this).closest('form').find('[name=bookingsRadio]:checked');

        writeDbLog("booked",$(this).closest('form').find('[type=checkbox]:checked').val(),"user_logs", "booking");

        var formData = $(this).closest('form').serialize();
        formData += "&do=saveBookingUser";
        //formData.append('do', 'saveBookingUser');
        //formData.append('id', id);

        var ajax = $.ajax({
            url: 'api/',
            data: formData,
            method: 'post',
            success: function (data) {
                if(data.error != undefined) {

                    var modaltext = Language.getItem("bookings-full-error");

                    var bookings = AppData.bookings.filter(function (be) {
                        return data.error.indexOf(be.id) > -1;
                    });

                    for (var b in bookings) {
                        modaltext += '<div class="full_booking">'+bookings[b].name[localState.lang]+'</div>';
                    }
                    Modal.alert(modaltext);
                } else {
                    //AppData.bookings = jQuery.parseJSON(data);
                    //Bookings.renderClient();
                    //data = null;
                    Modal.alert(Language.getItem('bookings-saved'));
                }
                if (data.bookings != undefined) {
                    AppData.bookings = data.bookings;
                    AppData.userbookings = data.userbookings;
                    Bookings.renderClient(localState.bookings.last_groupfilter);
                }

                Socket.Send("Bookings.BuildFromSourceClient", null, {client: "client"});
                Socket.Send("Bookings.Modify", null, {client: "booking"});

            },
            error: function (response) {
                console.log('Hiba: ' + response);
            }
        });
        //$('#bookings article').find('[type=submit]').addClass('disabled');
    }

    function getAjaxUserBooking() {

        var formData = new FormData();
        formData.append('do', 'getUserBooking');

        return $.ajax({
            url: 'api/',
            data: {
                do: 'getUserBooking'
            },
            method: 'post',
            async: false,
            success: function (data) {

               /* var has_approved = false;
                for (var i in data) {
                    if (data[i].approved) {
                        has_approved = true;
                        var booking = AppData.bookings.find(function (el) {
                            return el.id == data[i].booking_id;
                        });
                        //var ubitem = $('<div/>').addClass("ub_item");
                        //ubitem.append('<span>' + booking.name[localState.lang] + '</span>');
                        if (booking.link) {
                            //ubitem.append('<button class="btn join-tcroom" data-tcroom="' + booking.link + '">' + Language.getItem('join-room') + '</button>');
                            console.log($('#bookings [data-bid="'+data[i].booking_id+'"] .join-tcroom'));
                            $('#bookings [data-bid="'+data[i].booking_id+'"] .join-tcroom').removeClass("hidden");
                        }
                        //$('#bookings').find('.user-bookins').append(ubitem);
                    }
                }*/
            },
            error: function (response) {
                console.log('Hiba: ' + response);
            }
        });
    }

    function watchStream() {
        let bid = $(this).attr("data-broadcast");
        Layout.hideAll();
        Broadcast.showDetails(bid);
    }

    function joinTcRoom(e, tcroom) {
        if (tcroom == undefined) {
            tcroom = $(this).attr("data-tcroom");
        }

        if (tcroom.indexOf("rentit.hu") == -1) {
            window.open(tcroom);
            return;
        }

        Layout.hideAll();
        openTrueConfCall(tcroom, 'workshop');
    }

    function renderClient(groups) {
        var
            $self = $('#bookings-details .bookingsList'),
            $fragmentBookings = $(document.createDocumentFragment()),
            ajaxUserBooking = getAjaxUserBooking();

        AppData.bookings.sort(function(a, b){
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
        if(groups === undefined && localState.bookings.last_groupfilter !== undefined) {
            groups = localState.bookings.last_groupfilter;
        }
        localState.bookings.last_groupfilter = groups;

        var bookings = AppData.bookings.filter(function (e) {
            if (localState.bookings.itemIndex !== undefined && localState.bookings.itemIndex !== "") {
                return e.id === localState.bookings.itemIndex;
            } else {
                return e.active === true; //&& (groups == undefined || groups.indexOf(e.group) > -1);
            }
        });

        if (groups != undefined) {
            if (groups.length == 1) {
                var group = AppData.bookinggroups.find(function (el) {
                    return el.id == groups[0];
                });
                $("#bookings-details .header-secondary .data-label").html(group.title[localState.lang]);
            } else {
                $("#bookings-details .header-secondary .data-label").html("");
            }
        }

        var classList = 'state-checked animated pulse';
        var userBooking = (ajaxUserBooking.responseText === undefined ? [] : JSON.parse(ajaxUserBooking.responseText));

        $.each(bookings, function (i, el) {
            var $clone = $templateBookingsItem.clone();

            $clone.attr("data-group", el.group);
            $clone.find('.data-title').html(el.name[localState.lang]);
            $clone.find('[type=submit] span').html(Language.getItem("save"));

            if (el.limit > 0) {
                let free_places = el.limit - el.participant;
                if (free_places < 0) {
                    free_places = 0;
                }
                $clone.find('.data-title').append('<br>'+Language.getItem("free-bookings")+': '+free_places);
            }
            $clone.find('.buttons').attr("data-bid", el.id);

            $clone.find('.buttons .join-tcroom').html(Language.getItem('join-room')).addClass("hidden");
            $clone.find('.buttons .watch-stream').html(Language.getItem('watch-stream')).addClass("hidden");

            $clone.find('input').val(el.id);
            $clone.appendTo($fragmentBookings);

            var in_user = userBooking.find(function (ub) {
                return ub.booking_id == el.id;
            });

            if (el.broadcast) {
                $clone.find('.buttons .watch-stream').attr("data-broadcast", el.broadcast).removeClass("hidden");
            }

            if (AppData.modulsettings.save_button_rows) {
                $clone.find('.save-bookings').removeClass("hidden");
            }

            if (in_user != undefined) {
                var $label = $clone.find('input').closest('label');
                $label.toggleClass(classList, this.checked);
                $clone.find('input').prop("checked", true);

                if (el.link) {
                    $clone.find('.buttons .join-tcroom').attr("data-tcroom", el.link).removeClass("hidden");
                }

            } else {
                $clone.find('.buttons .join-tcroom').remove();
            }

            if (groups == undefined || groups.indexOf(el.group) > -1) {
                $clone.removeClass("hidden");
            } else {
                $clone.addClass("hidden");
            }
        });
        $self.html($fragmentBookings);
        $('#bookings').find('.no-result').toggle(Boolean(!AppData.bookings.length));
        $('#bookings article').find('.bookingsList').show();
    }

    function showDetails(id) {
        if (id) {
            if(id === "all") {
                localState.bookings.itemIndex = "";
                delete localState.bookings.last_groupfilter;
                renderClient();
                Layout.show('bookings-details');
            } else if (id === 'all_groups') {
                renderList();
                Layout.show('bookings');
            } else {
                var link = AppData.bookings.find(function (e) {
                    return id == e.id;
                });
                if (link != undefined) {
                    if (localState.bookings != undefined) {
                        if (id !== localState.bookings.itemIndex || localState.bookings.itemIndex === 0) {
                            localState.bookings.itemIndex = String(id);
                            renderClient(localState.bookings.last_groupfilter);
                        } else {
                            renderClient();
                        }
                        Layout.show('bookings-details');
                    } else {
                        localState.bookings = {'itemIndex': String(id)};
                        Layout.show('bookings-details');
                    }
                } else {
                    var group = AppData.bookinggroups.find(function (el) {
                        return id == el.id;
                    });

                    if (group != undefined) {
                        localState.bookings.itemIndex = "";
                        localState.bookings.last_groupfilter = [group.id];
                         renderClient([group.id]);
                    }
                }
                Layout.show('bookings-details');
            }
        }
    }

    function renderList() {

        let $self = $('#bookings .bookingsGroupList'),
            $fragmentBookings = $(document.createDocumentFragment());

        $.each(AppData.bookinggroups, function (i, el) {
            var $clone = $tplBookingsGroupItem.clone();

            $clone.attr("data-id", el.id);
            $clone.find('.data-name').html(el.title[localState.lang]);

            $clone.appendTo($fragmentBookings);
        });

        $self.html($fragmentBookings);
    }


    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function BuildFromSourceClient() {
        $.post('api/', {
            do: 'getBookings'
        }, function (data) {
            AppData.bookings = data.bookings;
            AppData.bookinggroups = data.bookinggroups;
            renderList();
            Bookings.renderClient(last_groupfilter);
            data = null;
            //Bookings.getAjaxUserBooking();
            getUserBookings();
        });
    }

    function getUserBookings() {
        $.post('api/', {
            do: 'getBookingsForUser'
        }, function (data) {
            AppData.userbookings = data;
            data = null;
            Agenda.BuildFromSource();
        });
    }

    function updateData() {
        renderList();
        Bookings.renderClient(last_groupfilter);
        getUserBookings();
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        renderClient: renderClient,
        showDetails: showDetails,
        BuildFromSourceClient: BuildFromSourceClient,
        getAjaxUserBooking: getAjaxUserBooking,
        getUserBookings: getUserBookings,
        joinTcRoom: joinTcRoom,
        updateData: updateData
    }
})();
