var Ondemandvideos = (function () {

    var $templateOndemandvideosItem;
    var $tplOndemandvideosDetailItem;
    var ondemandplayer;

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateOndemandvideosItem = $('template#tplOndemandvideosItem').contents(); // remove().
        $tplOndemandvideosDetailItem = $('template#tplOndemandvideosDetailItem').contents(); // remove().
        ondemandplayer = videojs('ondemandvideo',{
            controls : true,
        });

        Build();
        BuildDetails(localState.videogroup || "");
    }

    function sort() {
        AppData.ondemandvideos.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });

        AppData.videogroups.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function Build() {
        var $fragment = $(document.createDocumentFragment()),
            $clone = $templateOndemandvideosItem.clone();

        sort();

        $.each(AppData.videogroups, function (i, el) {
            if (el.visible == "1") {
                $clone = $templateOndemandvideosItem.clone();
                $clone.attr("data-id", el.id);
                $clone.find('.data-name').text(el.title[localState.lang]);
                $clone.appendTo($fragment);
            }
        });
        $('#ondemandvideos #ondemandvideosList').html($fragment);
    }

    function BuildDetails(group) {
        var $fragment = $(document.createDocumentFragment()),
            $clone = $tplOndemandvideosDetailItem.clone();

        sort();

        if (group != "") {
            let groupObj = AppData.videogroups.find(function (item) {
                return item.id == group;
            });

            $("#ondemandvideos-details .header-secondary .data-label").text(groupObj.title[localState.lang])
        }

        $.each(AppData.ondemandvideos, function (i, el) {
            if (el.visible && (el.group == group || group == "")) {
                $clone = $tplOndemandvideosDetailItem.clone();
                $clone.attr("data-id", el.id);
                if (el.download_url != "" && el.download_url != undefined) {
                    $clone.find('.download_link').attr("href", el.download_url);
                    $clone.find('.download_link').attr("download", el.download_url);
                    $clone.find('.download_link').removeClass("hidden");
                } else {
                    $clone.find('.download_link').attr("href", "");
                    $clone.find('.download_link').attr("download", "");
                    $clone.find('.download_link').addClass("hidden");
                }
                $clone.find('.data-name').html(el.title[localState.lang]);
                $clone.appendTo($fragment);
            }
        });
        $('#ondemandvideos-details #ondemandvideosdetailsList').html($fragment);
    }

    $(document).on('click', '#ondemandvideos-details #ondemandvideosdetailsList .item .download_link', function (e) {
        e.stopPropagation();

        /*let id = $(this).closest(".item").attr("data-id");
        let elem = AppData.ondemandvideos.find(function (item) {
            return item.id == id;
        });

        if (elem) {
            window.open(elem.download_url);
        }*/
    });

    $(document).on('click', '#ondemandvideosList .item', function (e) {
        showDetailsList($(this).attr("data-id"));
    });

    $(document).on('click', '#ondemandvideosdetailsList .item', function (e) {
        showDetails($(this).attr("data-id"));
    });

    $(document).on('click', '.ondemandvideo_viewer .close_video', function (e) {
        ondemandplayer.pause();
        let actual_stream = $('.ondemandvideo_viewer').attr("data-stream");
        $('.ondemandvideo_viewer').attr("data-stream", "").hide();
    });

    function showDetails(id) {

        if (id == 'all') {
            showDetailsList("");
        } else if (id == "all_groups") {
            Build();
            Layout.show("ondemandvideos");
        } else {
            var item = AppData.ondemandvideos.find(function (el) {
                return el.id == id;
            });

            if (item != undefined) {
                ondemandplayer.reset();
                ondemandplayer.src(item.url+'?siteid='+AppData.settings.site_id+'&uid='+AppData.profile.id);
                ondemandplayer.play();
                writeDbLog("play video", String(item.id), "user_logs", "ondemandvideos");
                $('.ondemandvideo_viewer').attr("data-stream", item.url).css("display", "flex").hide().fadeIn(400);

                logVideoWatch('start_watching', item.url+'?siteid='+AppData.settings.site_id+'&uid='+AppData.profile.id);
            } else {
                let group = AppData.videogroups.find(function (g) {
                    return g.id == id;
                });

                if (group) {
                    showDetailsList(id);
                }
            }
        }
    }

    /*function showList(group) {
        if (group == "all") {
            showDetailsList("");
        } else {
            Build(group);
            Layout.show("ondemandvideos");
        }
    }*/

    function showDetailsList(group) {
        //if (group) {
        BuildDetails(group || "");

        localState.videogroup = group;

        Layout.show("ondemandvideos-details");
        //}
    }

    function getPlayer(){
        return ondemandplayer;
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getOndemandvideos'
        }, function (data) {
            AppData.ondemandvideos = data.videos;
            AppData.videogroups = data.video_groups;
            Init();
        });
    }

    function updateData() {
        Build();
        BuildDetails(localState.videogroup || "");
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        updateData: updateData,
        BuildFromSource: BuildFromSource,
        BuildBroadcast: Build,
        showDetails: showDetails,
        //showList: showList,
        getPlayer: getPlayer,
    }
})();
