/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
var Channels = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateChannelItem;

    /* ----- Init Channels -------------------------------------------------------------------------------------------- */
    function Init() {
        $templateChannelItem = $('template#tplChannelItem').remove().contents();
        Build();

        if ('currentChannel' in localState && localState.currentChannel != undefined && localState.currentChannel != "undefined") {
            localStorage.setItem('rca4_current_channel', localState.currentChannel);
        }
        localState.currentChannel = localStorage.getItem('rca4_current_channel') || ['ch0'];
        $('#channelList [data-channel=' + localState.currentChannel + ']').trigger('click');
        delete Channels.Init;
    }

    /* ----- Change Channels ------------------------------------------------------------------------------------------ */
    function update() {
        $.post('api/', {
            do: 'getChannels'
        }, function (data) {
            AppData.channels = data;
            Build();
            data = null;
        })
    }

    function updateData() {
        Build();
    }

    function sort() {
        AppData.channels.sort(function (a, b) {
            return parseInt(a.sort_id) - parseInt(b.sort_id);
        });
    }

    /* ----- Build Channels ------------------------------------------------------------------------------------------- */
    function Build() {
        sort();
        var $fragmentChannels = $(document.createDocumentFragment()); // bungi
        $.each(AppData.channels, function (i, el) {
            if (el.active && el.name) {
                var $clone = $templateChannelItem.clone();
                $clone.find('.data-id').text(el.channel);
                $clone.find('.data-name').text(el.name[localState.lang]);
                $clone.attr('data-channel', el.channel);
                if (localState.currentChannel === el.channel) {
                    $clone.addClass('color-primary');
                }
                $clone.appendTo($fragmentChannels);
            }
        });
        $('#channels #channelList').html($fragmentChannels);
    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    $(document).on('click', '#channelList .item', function (e) {
        var $this = $(this);
        localState.currentChannel = $this.data('channel');
        if (localState.currentChannel in (AppData.presentations || [])) {
            Presentation.source(AppData.presentations[localState.currentChannel]);
            SpeakerPresentation.source(AppData.presentations[localState.currentChannel]);
            if (AppData.modulsettings.presentation_showtospeaker) {
                SpeakerPresentation.show();
            }
        } else {
            Presentation.source('');
            SpeakerPresentation.source('');
        }
        if (e.originalEvent) {
            localStorage.setItem('rca4_current_channel', $this.data('channel'));
            Socket.Send('Channels.advertise', localState.currentChannel);
        }
        $this.addClass('color-primary').siblings().removeClass('color-primary');
        $('#channels').find('.close-main').removeClass('disabled');
    });
    //  $( document ).on( 'socketOnline', function () {
    //    if ( localState.currentChannel ) {
    //      Socket.Send( 'Channels.advertise', localState.currentChannel );
    //    }
    //  } );
    function setChannel(data) {
        localState.currentChannel = data;
        localStorage.setItem('rca4_current_channel', data);
        Socket.Send('Channels.advertise', data);
        Build(); // bungi
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        update: update,
        updateData: updateData,
        setChannel: setChannel
    };
})();
