/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Useful = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateUsefulItem;
    var useful;

    /* ----- Init Agenda ---------------------------------------------------------------------------------------------- */
    function Init() {
        $templateUsefulItem = $('template#tplUsefulItem').remove().contents();

        useful = AppData.docs.filter(function (el) {
            return el.type == "useful" && el.visible;
        });

        renderList();
        renderDetails();
        $(document).on('click', '#useful .item', evtClick);
        delete Useful.Init;
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortUseful() {
        useful.sort(function (a, b) {
            return a.title[localState.lang].localeCompare(b.title[localState.lang]);
        });
    }

    /* ----- Build Useful List ---------------------------------------------------------------------------------------- */
    function renderList() {
        sortUseful();
        var
            $self = $('#useful #usefulList'),
            $fragmentUseful = $(document.createDocumentFragment()),
            data = useful.filter(function (el) {
                return el.visible === true;
            });
        /* Set Agenda List */
        $.each(data, function (i, el) {
            var $clone = $templateUsefulItem.clone();
            $clone.attr('data-index', el.id);
            $clone.find('.data-title').text(el.title[localState.lang]);
            $clone.appendTo($fragmentUseful);
        });
        $self.html($fragmentUseful);
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        localState.useful = {
            itemIndex: $(this).data('index') || 0
        };
        renderDetails();
        Layout.show('useful-details');
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails() {
        if ('useful' in localState) {
            var
                $header = $('#useful-details .data-label'),
                el = useful.find(function (el) {
                    return el.id === localState.useful.itemIndex
                }) || useful[0];
            if (!el) {
                return false;
            }
            $header.text(el.title[localState.lang]);
            $('#useful-details embed').attr('src', './pdfjs/web/viewer.html?file=../../upload/docs/' + el.files[localState.lang].filename);
        }
    }

    function showDetails(id) {
        if (id) {
            if (localState.useful != undefined) {
                if (id !== localState.useful.itemIndex || localState.useful.itemIndex === 0) {
                    localState.useful.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.useful = { 'itemIndex': String(id)};
            }
            Layout.show('useful-details');
        }
    }

    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource() {
        $.post('api/', {
            do: 'getDocs'
        }, function (data) {
            AppData.docs = data;
            useful = AppData.docs.filter(function (el) {
                return el.type == "useful" && el.visible;
            });
            renderList();
            data = null;
        })
    }

    function updateData() {
        renderList();
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        updateData: updateData,
        showDetails:showDetails,
        BuildFromSource: BuildFromSource
    };
})();
