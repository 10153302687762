/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Speakers = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        $templateSpeakersItem,
        $templateSpeakersAgenda;
    localState.speakers = localState.speakers || {};
    localState.speakers.itemIndex = localState.speakers.itemIndex || 0;
    localState.speakers.count = localState.attendees.count || 0;
    localState.speakers.page = 0;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateSpeakersItem = $('template#tplSpeakersItem').remove().contents();
        $templateSpeakersAgenda = $('template#tplSpeakersAgenda').remove().contents();
        $(document)
            .on('input', '#speakers .search-wrapper input', evtInput)
            .on('keyup', '#speakers .search-wrapper input', evtPressEnter)
            .on('click', '#speakers .search-wrapper .btn-clear', evtClear)
            .on('click', '#speakers #speakersList .item', evtClick)
            .on('click', '#speakers-details .favorite.save', evtClickFavorite)
            .on('click', '#speakers-details .favorite.cancel', evtClickCancelFavorite)
            .on('click', '#speakers-details .item.agenda', Agenda.evtClickDetails);
        $(document).on('click', '#speakers-details .external-link', openExternalLink);
        if ('search' in localState.speakers) {
            $('#speakers .search-wrapper input').val(localState.speakers.search).trigger('input');
        }
        toggleRequestBusinessCard();
        toggleRequestSpeakerGotoLink();
        UpdateModule();
    }

    function sort() {
        AppData.user_fields.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function addUserFields(data) {
        sort();
        var user_fields = AppData.user_fields.filter(function(el) {
                return el.is_active === true;
            }),
            div,
            icon,
            section;

        $("#speakers-details .custom_fields").html("");
        $.each(user_fields, function(i,el) {
            section = $("<section/>");
            section.addClass("item " + (el.field_type === "text" ? "text-center" : "text-justify"));
            div = $("<div/>");
            section.append(div);

            icon = "";
            if(el.field_icon !== "") {
                icon = "<i class='" + el.field_icon + "'></i> ";
            }
            if(el.field_translated) {
                if(data && data.values[el.field_name].title !== '') {
                    div.html(icon + data.values[el.field_name].title[localState.lang]);
                    $("#speakers-details .custom_fields").append(section);
                }
            } else {
                if(data && data.values[el.field_name].title !== '') {
                    div.html(icon + data.values[el.field_name].title);
                    $("#speakers-details .custom_fields").append(section);
                }
            }
        });
    }

    function openExternalLink() {
        var link = $(this).attr("data-link");
        window.open(link);
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortList() {
        AppData.speakers.sort(function (a, b) {
            if (AppData.modulsettings.speakersabc) {
                return a.name[localState.lang].localeCompare(b.name[localState.lang]);
            } else {
                if (a.sort_id !== undefined && b.sort_id !== undefined) {
                    return parseInt(a.sort_id) - parseInt(b.sort_id);
                } else {
                    return 0;
                }
            }
        });
    }

    /* ----- Build List ----------------------------------------------------------------------------------------------- */
    function renderList() {
        sortList();

        var
            $self = $('#speakers #speakersList'),
            $fragmentSpeakers = $(document.createDocumentFragment()),
            data = AppData.speakers;
        /* Set Agenda List */
        $.each(data, function (i, el) {
            var $clone = $templateSpeakersItem.clone(),
                info = [];

            $clone.attr('data-index', el.id);

            if(AppData.modulsettings.showspeakergotolink === true) {
                $clone.toggleClass("link");
            } else {
                $clone.removeClass("link");
                $clone.find('.chevron').hide();
            }

            $.each(el.values, function(i,e) {
                if(e.showrow === '1' && e.is_active) {
                    info.unshift(typeof e.title == "object" ? e.title[localState.lang] : e.title);
                } else if(e.showrow === '2' && e.is_active) {
                    info.push(typeof e.title == "object" ? e.title[localState.lang] : e.title);
                }
            });

            $clone.find('.data-name').text(el.name[localState.lang]);
            $clone.find('.data-info').html(info.join('<br>')).toggleClass('hidden', !info.length);
            if (el.img || false) {
                $clone.find('.data-img').attr(($self.is(':visible')) ? 'src' : 'data-src', 'upload/users/' + el.img );
            } else {
                $clone.find('.data-img').attr('src', 'img/profile-picture-placeholder.png');
            }
            $clone.appendTo($fragmentSpeakers);
        });
        $self.html($fragmentSpeakers);
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails() {
        if ('speakers' in localState) {
            var
                $speaker = $('#speakers-details article'),
                $header = $('#speakers-details .data-label'),
                el = AppData.speakers.find(function (item) {
                    return item.id === localState.speakers.itemIndex
                }),
                item = AppData.favorites.find(function (item) {
                    return item.type === 'speaker' && item.data === localState.speakers.itemIndex
                }),
                saved = Boolean(item),
                undo  = Boolean(item) && !item.sent,
                agenda;
            if (!el) {
                return false;
            }

            agenda = AppData.agenda.filter(function (item) {
                var speakers = item.speakers;
                var speaker;
                if (speakers) {
                    speaker = speakers.filter(function (s) {
                        return s.speaker_id == el.id;
                    });
                    return speaker.length > 0;
                }
                //return String(item.speakers).split(',').indexOf(el.id) !== -1;
                return false;
            });

            $header.text(el.name[localState.lang] || '');

            $speaker.find('.data-img').attr({
                'src': 'img/profile-picture-placeholder.png',
                'data-src': null
            });
            if (el.img || false) {
                $speaker.find('.data-img').attr('src', 'upload/users/' + el.img);
            }
            $speaker.find('.data-name').text(el.name[localState.lang]);
            var
                phone = el.phone ? atob(atob(el.phone).substring(1)) : '',
                email = el.email ? atob(atob(el.email).substring(1)) : '';

            let fb = el.facebook_url ? el.facebook_url : '';
            let tw = el.twitter_url ? el.twitter_url : '';
            let ins = el.instagram_url ? el.instagram_url : '';
            let li = el.linkedin_url ? el.linkedin_url : '';

            $speaker.find('.facebook').attr("data-link", fb).toggleClass('hidden', !fb);
            $speaker.find('.twitter').attr("data-link", tw).toggleClass('hidden', !tw);
            $speaker.find('.instagram').attr("data-link", ins).toggleClass('hidden', !ins);
            $speaker.find('.linkedin').attr("data-link", li).toggleClass('hidden', !li);

            $speaker.find('.social').toggleClass('hidden', !fb && !tw && !ins && !li);

            $speaker.find('.data-phone').text(phone).parent().toggleClass('hidden', !el.phone);
            $speaker.find('.data-email').text(email).parent().toggleClass('hidden', !el.email);
            $speaker.find('.data-job').text(el.job[localState.lang]).toggleClass('hidden', !el.job[localState.lang]);
            $speaker.find('.data-company').text(el.company[localState.lang]).parent().toggleClass('hidden', !el.company[localState.lang]);
            $speaker.find('.data-bio').html(nl2br(el.bio[localState.lang])).parent().toggleClass('hidden', !el.bio[localState.lang]);
            $speaker.find('.data-department').text(el.department[localState.lang]).parent().toggleClass('hidden', !el.department[localState.lang]);
            $speaker.find('.data-slogan').text(el.slogan[localState.lang]).parent().toggleClass('hidden', !el.slogan[localState.lang]);
            $speaker.find('.data-skill').text(el.skill[localState.lang]).parent().toggleClass('hidden', !el.skill[localState.lang]);
            $speaker.find('.data-university').text(el.university[localState.lang]).parent().toggleClass('hidden', !el.university[localState.lang]);
            $speaker.find('.favorite.save').toggleClass('hidden', saved);
            $speaker.find('.favorite.saved').toggleClass('hidden', !saved);
            $speaker.find('.favorite.cancel').toggleClass('hidden', !undo);
            $speaker.find('.agendaList').toggleClass('hidden', !agenda.length);
            $speaker.find('.item.agenda').remove();
            if (agenda) {
                var fragmentAgenda = $(document.createDocumentFragment()),
                timeFormat = AppData.translations.filter(function(e) {
                    return e.translation_id === localState.lang;
                })[0];

                $.each(agenda, function (i, item) {
                    var $cloneAgenda = $templateSpeakersAgenda.clone();
                    $cloneAgenda.attr('data-id', item.id);

                    var atitle;
                    if (typeof item.title == "string") {
                        atitle = item.title;
                    } else {
                        atitle = item.title[localState.lang];
                    }

                    let parentAgenda;
                    if (AppData.modulsettings.agendashowparentonspeaker) {
                        if (item.parent != "") {
                            let pid = item.parent;
                            parentAgenda = AppData.agenda.find(function (p) {
                                return p.id == pid;
                            });
                            if (parentAgenda) {
                                atitle = parentAgenda.title[localState.lang] + '<br><span class="underparent">' + atitle + '</span>';
                            }
                        }
                    }

                    var day = AppData.days.filter(function (el) {
                        return item.day == el.id;
                    });

                    $cloneAgenda.find('.data-agendaName').html(day[0].name[localState.lang]);
                    $cloneAgenda.find('.data-title').html(atitle);
                    $cloneAgenda.find('.data-timeStart').text(makeTimeFormat(item.timeStart, timeFormat.time_format));
                    $cloneAgenda.find('.data-timeEnd').text(makeTimeFormat(item.timeEnd, timeFormat.time_format));
                    var room = AppData.channels.find(function(e) {
                        return e.channel === item.rooms;
                    });
                    $cloneAgenda.find('.data-rooms')
                        .attr("data-room-id", item.rooms).text(room ? room.name[localState.lang] : "")
                        .parent().toggleClass('hidden', !room);
                    $cloneAgenda.appendTo(fragmentAgenda);
                    $cloneAgenda = null;
                });
                fragmentAgenda.appendTo($speaker.find('.agendaList'));
                fragmentAgenda = null;
            }

            addUserFields(el);
            toggleRequestBusinessCard();
            toggleSocial();
        }
    }

    function toggleRequestBusinessCard() {
        if(AppData.modulsettings.showrequestbusinesscard) {
            $("#speakers-details").find(".favorite").show();
        } else {
            $("#speakers-details").find(".favorite").hide();
        }
    }

    function toggleSocial() {
        if(AppData.modulsettings.showsociallinks) {
            $("#speakers-details").find(".social").show();
        } else {
            $("#speakers-details").find(".social").hide();
        }
    }

    /* ----- updateRequestBusinessCard ------------------------------------------------------------------------------ */
    function updateRequestBusinessCard() {
        $.post('api/', {
            do: 'getFavorites'
        }, function (data) {
            AppData.favorites = data;
            renderDetails();
            data = null;
        })
    }

    function toggleRequestSpeakerGotoLink() {

        $('#speakers #speakersList .item').each(function (i, item) {
            if(AppData.modulsettings.showspeakergotolink === true) {
                $(item).addClass("link");
                $(item).find(".chevron").show();
            } else {
                $(item).removeClass("link");
                $(item).find(".chevron").hide();
            }
        });
    }


    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource() {
        $.post('api/', {
            do: 'getSpeakers'
        }, function (data) {
            AppData.speakers = data;
            UpdateModule();
            lazyImages();
            data = null;
        })
    }

    /* ----- Update Avatar -------------------------------------------------------------------------------------------- */
    function updateAvatar_old(img) {
        $.get(img, function () {
            BuildFromSource();
        })
    }

    function updateAvatar(img) {
        $("#speakers [data-index='"+img.id+"'] .data-img").attr('src', img.img+"?r="+Math.random());
        let userindex = AppData.speakers.findIndex(function (item) {
            return item.id == img.id;
        });

        if (userindex > -1) {
            let imgname = img.img.split("/");
            AppData.speakers[userindex].img = imgname[imgname.length - 1];
        }
    }

    function removeAvatar(id) {
        $("#speakers [data-index='"+id+"'] .data-img").attr('src', "img/profile-picture-placeholder.png");
        let userindex = AppData.speakers.findIndex(function (item) {
            return item.id == id;
        });
        if (userindex > -1) {
            AppData.speakers[userindex].img = "";
        }
    }

    function removeUser(data) {
        let userindex = AppData.speakers.findIndex(function (item) {
            return item.id == data;
        });

        if (userindex > -1) {
            AppData.speakers = AppData.attendees.filter(function(e){
                return e.id !== data;
            });
            UpdateModule();
        }
    }

    /* ----- Event: Input on Search ----------------------------------------------------------------------------------- */
    function evtInput(e) {
        var
            isResult = false,
            $btnClear = $('#speakers .search-wrapper .btn-clear'),
            $noResult = $('#speakers .no-result'),
            value = $('#speakers .search-wrapper input').val(),
            search = value.split(' ').join(''),
            regex1 = new RegExp(strCanonical(search), 'i'),
            regex2 = new RegExp(strCanonical(search), 'i'),
            regex3 = new RegExp(strCanonical(search), 'i');
        localState.speakers.search = value;
        requestAnimationFrame(function () {
            $('#speakers #speakersList .item').each(function (i, el) {
                var
                    str1 = $(el).find('.data-name').text().split(' ').join(''),
                    str2 = $(el).find('.data-job').text().split(' ').join(''),
                    str3 = $(el).find('.data-company').text().split(' ').join(''),
                    isMatching =
                        regex1.test(strCanonical(str1)) ||
                        regex2.test(strCanonical(str2)) ||
                        regex3.test(strCanonical(str3));
                $(el).toggle(isMatching);
                isResult = (isMatching) ? true : isResult;
                str1 = str2 = str3 = isMatching = null;
            });
            $btnClear.toggle(Boolean(value.length));
            $noResult.toggle(!isResult);
            isResult = $btnClear = $noResult = value = search = regex1 = regex2 = regex3 = null;
        });
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        if(AppData.modulsettings.showspeakergotolink === true) {
            localState.speakers.itemIndex = String($(this).data('index')) || 0;
            renderDetails();
            Layout.hide('speakers-details', true);
            Layout.show('speakers-details');
            writeDbLog("open speaker profile", localState.speakers.itemIndex, "user_logs", "speakers");
        }

    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClickFavorite() {
        var $self = $('#speakers-details');
        $self.find('.favorite.save').addClass('hidden');
        $self.find('.favorite.saved').removeClass('hidden');
        $self.find('.favorite.cancel').removeClass('hidden');
        $.post('api/', {
            do: 'saveFavorite',
            data: {'id' : localState.speakers.itemIndex || false, 'lang': localState.lang}
        }, function (res) {
            AppData.favorites = res;
            Modal.resultconfirmation(
                {"canClose": true,
                    "title": AppData.languages[localState.lang].businesscardrequest_title,
                    "text":  AppData.languages[localState.lang].businesscardrequest_text
                }
            );
            res = null;
        });
        $self = null;
    }

    function evtClickCancelFavorite() {
        var
            $self = $('#speakers-details'),
            saved = AppData.favorites.find(function (item) {
                return item.type === 'speaker' && item.data === localState.speakers.itemIndex
            });
        $self.find('.favorite.save').removeClass('hidden');
        $self.find('.favorite.saved').addClass('hidden');
        $self.find('.favorite.cancel').addClass('hidden');
        $.post('api/', {
            do: 'removeFavorite',
            data: saved.id
        }, function (res) {
            AppData.favorites = res;
            res = null;
        });
        $self = null;
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClear(e) {
        e.preventDefault();
        var $input = $('#speakers .search-wrapper input');
        $input.val('').trigger('input');
    }

    /* ----- Event: Press Enter --------------------------------------------------------------------------------------- */
    function evtPressEnter(e) {
        if (e.wich || e.keyCode === 13) {
            this.blur();
        }
    }

    function showDetails(id) {
        if (id) {
            if (localState.speakers != undefined) {
                if (id !== localState.speakers.itemIndex || localState.speakers.itemIndex === 0) {
                    localState.speakers.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.speakers = { 'itemIndex': String(id)};
            }
            Layout.show('speakers-details');
            writeDbLog("open speaker profile", id, "user_logs", "speakers");
        }
    }

    function UpdateModule() {
        // sortList();
        renderList();
        renderDetails();
    }

    function updateData() {
        renderList();
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        evtClickDetails: evtClick,
        showDetails: showDetails,
        renderList: renderList,
        renderDetails: renderDetails,
        BuildFromSource: BuildFromSource,
        updateAvatar: updateAvatar,
        removeAvatar:removeAvatar,
        removeUser: removeUser,
        toggleRequestBusinessCard: toggleRequestBusinessCard,
        toggleRequestSpeakerGotoLink: toggleRequestSpeakerGotoLink,
        updateRequestBusinessCard: updateRequestBusinessCard,
        toggleSocial: toggleSocial,
        UpdateModule: UpdateModule,
        updateData: updateData
    };
})();
